<template>
    <div class="custom-loading">
        <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        if (this.$store.state.AppActiveUser.availableTypes.includes("penal")) {
            this.$router.replace({ name: 'main-cases' })
        } else if (this.$store.state.AppActiveUser.availableTypes.includes("civil")) {
            this.$router.replace({ name: 'civil-cases' })
        } else {
            console.error("NO SE RECONOCE EL TIPO DE CASOS QUE PUEDE VER ESTE USUARIO.")
        }
    },
}
</script>
<style lang="scss" scoped>

.custom-loading {
  position: relative;
  width: 100%;
  height: 50vh;

  .loading {
    top: calc(50% - 35px);
  }
}

</style>